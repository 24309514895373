<template>
  <div>
    <nav-bar :isNavWx="true" :bgc="'background:#fff'" :isFixed="true" :title="$t('訂單詳情')"></nav-bar>
  </div>
</template>
<script>
import { getOrderProductList } from "@/api/user.js"
import mixins from "@/utils/mixins.js"
export default {
  name: "returnGoodsDetil",
  mixins: [mixins],
  data () {
    return {
      
    }
  },
  mounted () {
    // const orderNo = this.$route.params.orderNo
    // if (orderNo) {
    //   this.getData(orderNo)
    // }
  },
  methods: {
    async getData (data) {
      const res = await getOrderProductList({ id: data })
    }
  }
}
</script>
<style scoped lang="scss">

</style>